import styles from "./tag.css";

export const links = () => [{ rel: "stylesheet", href: styles }];

export interface TagProps {
  label: string;
  color: string;
  spacing?: boolean;
}

export const Tag = ({ label, color, spacing }: TagProps) => {
  return (
    <span className="tag-container" style={{ backgroundColor: color, marginLeft: spacing ? 8 : 0 }}>
      <span className="ellipse">
        <span className="tag-text">{label}</span>
      </span>
    </span>);

};